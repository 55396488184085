// Theme-specific
$theme-name: "leukoplast";
$is-tena-theme: false;

// Fonts
$fontfamily-exo2: "Exo2", $fontfamily-fallback;
$fontfamily-exo2-bold: "Exo2SemiBold", $fontfamily-fallback;
$fontfamily-regular: $fontfamily-exo2;
$fontfamily-regular-italic: $fontfamily-exo2;
$fontfamily-regular-bold: $fontfamily-exo2-bold;
$fontfamily-titles-normal: $fontfamily-exo2;
$fontfamily-titles-thin: $fontfamily-exo2;
$fontfamily-header: $fontfamily-exo2;
$fontfamily-header-thin: $fontfamily-exo2;
$campaign-subtitle-font: $fontfamily-regular-bold;
$intro-title-font-family: $fontfamily-exo2;
$fontfamily-regular-light: $fontfamily-regular;

// Font sizes
$font-size-h2: 40px;
$font-size-h3: 28px;
$font-size-h4: 22px;

$font-size-section-title: $font-size-h2;
$font-size-accordion-title: (def: 16px);
$line-height-accordion-title: (def: 22px);
$font-size-btn: (def: 18px, xs: 16px) !default;
$article-h3-font-size: $font-size-h3;
$font-size-paragraph: 16px;
$font-size-intro-carousel-title: (def: 40px, md: 28px);
$line-height-intro-carousel-title: (def: 44px, md: 32px);
$font-size-preamble: $font-size-h3;
$font-size-header-title: (def: 26px, sm: 19px);
$font-size-menu-item: (def: 20px, md: 22px);
$paragraph-line-height: 22px;
$btn-min-width: 210px;
$line-height-section-title: 44px;
$font-size-section-title-large: 40px;
$line-height-section-title-large: 44px;
$letter-spacing-section-title-large: -0.5px;
$simple-list-heading-font-size: (def: 28px, xs: 22px);
$simple-list-heading-line-height: (def: 32px, xs: 28px);

// Colors
$logo-color: #E40428;
$segment-color: #9CA9B1;
$menu-arrow-border-color: $segment-color;
$menu-submenu-border-color: $segment-color;
$segment-color-alt: #5C6E78;
$home-color: $segment-color-alt;

// Crate
$crate-background-color: #e9f4fa;

$accent-color: $segment-color;
$accent-color-alt: $color-mid-light-blue;

$caption-background: $logo-color;
$caption-background-transparent: rgba($logo-color, 0.8);

$border-color: $segment-color;
$border-color-alt: $crate-border-color;
$background-color-alt: #E6EFF5;
$link-color: $accent-color;
$btn-play-background: $logo-color;
$btn-play-background-transparent: rgba($logo-color, 0.8);

// Header
$header-section-title-color: $text-color;
$input-search-border-color-active: $segment-color-alt;
$icon-cart-color: #5C6E78;
$header-height: 167px;
$header-links-color: $segment-color-alt;
$mobile-header-menu-background: $segment-color;
$mobile-header-trigger-color: $segment-color-alt;
$header-menu-item-title-scroll-color: $segment-color-alt;
$header-menu-item-title-scroll-hover: $logo-color;
$mobile-header-search-close-icon-color: $segment-color-alt;
$mobile-header-details-position-bottom: 20px;
$header-country-selector-label-font-size: 13px;
$header-country-selector-label-line-height: 18px;
$header-country-selector-label-color: #5C6E78;

// Footer
$footer-bg-color: $segment-color;
$footer-font-color: $color-white;
$footer-toolbar-background-color: transparent;
$font-size-footer-toolbar: 17px;
$footer-disclaimer-heading-font-family: $fontfamily-titles-normal;
$footer-disclaimer-heading-color: $logo-color;
$footer-disclaimer-footnote-color: $segment-color-alt;
$footer-disclaimer-border-color: $logo-color;

/**
* Components
*/

// Article
$article-h3-margin: 0 0 13px;
$article-list-title-font: $fontfamily-regular-bold;

// Btn
$btn-font-family: $fontfamily-regular;
$btn-padding-icon: 18px;
$btn-border-radius: 100px;
$cta-bgcolor: transparent;
$cta-bgcolor-alt: #5C6E78;
$cta-bg-hover: #5C6E78;
$btn-text-aligment: center;

// Image Carousel
$image-carousel-arrow-color: $segment-color;
$image-carousel-thumbnail-border-color: $border-color;
$image-carousel-arrow-hover-color: $accent-color-alt;
$image-carousel-enlarge-stroke-color: #E40428;

// Popup buy now
$popup-product-title-font-size: (def: 28px, xs: 22px);
$popup-product-title-line-height: (def: 32px, xs: 26px);
$popup-product-title-font-family: $fontfamily-regular;
$popup-product-title-color: $segment-color-alt;
$popup-product-close-button-background: $segment-color;
$popup-product-select-font-size: (def: 16px);
$popup-product-select-color: $segment-color-alt;
$popup-product-select-font-family: $fontfamily-regular-bold;
$popup-product-select2-background: #E8EFF4;
$popup-product-details-font-size: (def: 16px);
$popup-product-details-line-height: (def: 22px);
$popup-product-details-font-fammily: $fontfamily-regular;
$popup-product-details-color: $segment-color-alt;
$popup-product-details-border-color: #D6DEE3;

// Intro
$intro-text-font-size: $font-size-paragraph;
$intro-title-font-size: (def: 48px, xs: 40px);
$intro-title-line-height: (def: 48px, xs: 44px);
$intro-line-height: $paragraph-line-height;
$intro-text-font-family: $fontfamily-regular;
$intro-text-color: $home-color;

// Product Intro
$product-intro-font-color: #fff;
$product-box-text-margin-vertical: 25px;

// Pushbox
$pushbox-padding: (def: 25px, sm: 25px, xs: 15px);
$push-box-title-font: $fontfamily-regular;
$push-box-title-transform: none;
$push-box-pretitle-transform: none;
$font-size-pushbox-title: 22px;
$push-box-border-color-alt: $segment-color;
$push-box-border-radius: 0;
$push-box-border-color: none;

// CTA
$cta-title-font: $fontfamily-header;
$cta-padding: (def: 43px 0, md: 25px 0, xs: 20px 0, xxs: 20px) !default;
$font-size-cta-text: 16px;
$cta-btn-font-size: 16px;
$cta-background-light-color: $color-white;

// Pagination
$pagination-selected-background-color: $accent-color-alt;
$pagination-selected-hover-background-color: transparent;
$pagination-arrow-icon-color: $logo-color;

// Carousel
$swiper-pagination-color: transparent;
$swiper-pagination-border-color: $logo-color;
$swiper-pagination-active-color: $logo-color;
$swiper-pagination-active-border-color: $logo-color;
$swiper-pagination-color-hover: rgba($color-black, 0.27);
$swiper-pagination-border-color-hover: $logo-color;
$swiper-pagination-active-color-hover: $logo-color;
$swiper-pagination-active-border-color-hover: $logo-color;

// table
$table-row-even-color: rgba(249, 246, 242, 0.5);

$header-logo-width: 192px;
$mobile-header-logo-width: 150px;
$submenu-link-color: $segment-color-alt;
$submenu-link-hover: $logo-color;

// Campaign
$font-size-campaign-image-title-large: (def: 40px);
$line-height-campaign-image-title-large: (def: 44px);
$letter-spacing-campaign-image-title-large: -0.5px;
$campaign-media-title-font: $fontfamily-regular;
$campaing-image-h4-padding-bottom: 10px;
$line-height-campaign-image-title: (def: 32px);
$font-size-caption-image-header: (def: 48px, sm: 28px);
$line-height-caption-image-header: (def: 48px, sm: 32px);
$campaign-light-background-color: $segment-color-alt;
$campaign-buttons-margin: 24px;

// Banner
$font-size-caption-image: 22px;
$line-height-caption-image: 28px;
$font-size-caption-image-subheader: 16px;

// Forms
$font-size-form-fields: (def: 17px);

// Product
$intro-product-detail-title-font-size: (def: 40px, md: 35px, xxs: 25px);
$font-size-product-box-text: 16px;
$product-box-text-margin: 0 20px;
$product-box-image-left-font-size: 16px;

// Table
$table-row-even-color: #D4E1E9;
$table-row-odd-color: $background-color-alt;
$table-light-border-color: $color-white;
$table-filter-active-color: #D22E33;
$table-filter-controls-color: $segment-color-alt;

// Accordion
$accordion-border-radius: 0;
$accordion-bg-color: $background-color-alt;

// Sitemap
$sitemap-list-title-font-size: 25px;

// Call to action
$cta-background-color: $logo-color;

// Promo banner
$promo-banner-title-font: $fontfamily-regular;
$promo-banner-title-font-size: (def: 40px, sm: 28px);
$promo-banner-title-line-height: (def: 44px, sm: 32px);
$promo-banner-desc-font-size: (def: 16px);
$promo-banner-desc-line-height: (def: 22px);
$promo-banner-desc-font: $fontfamily-regular;
$promo-banner-bg-color: $logo-color;

// Quotation (Testimonial)
$testimonials-quote-font-family: $fontfamily-regular;
$testimonials-quote-color: $segment-color-alt;
$testimonials-quote-letter-spacing: -1px;
$testimonials-quote-info-font-family: $fontfamily-regular;
$testimonials-quote-info-color: $segment-color-alt;
$testimonials-quote-left-img: '../img/quotation/ic-quotation-leukoplast-left.png';
$testimonials-quote-right-img: '../img/quotation/ic-quotation-leukoplast-right.png';

// Country list submenu
$country-language-selected-color: #E40428;